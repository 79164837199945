import { useState, useEffect } from 'react';
import { themePicker } from '../theme/themePicker';


const SIBYLITY_LOGO = "https://cdn.sibylity.com/static/branding/sibylity/img/logo.svg";
const SIBYLITY_THUMBNAIL = "https://cdn.sibylity.com/static/branding/sibylity/img/thumbnail.svg";
const SIBYLITY_HELP_RIGHT = "https://cdn.sibylity.com/static/branding/sibylity/img/help-right.svg";
const SIBYLITY_ICON = "https://cdn.sibylity.com/static/branding/sibylity/img/favicon.ico";

export const useBranding = (tenant, themeKey = 'sibylity') => {
    const [logo, setLogo] = useState(SIBYLITY_LOGO);
    const [favicon, setFavicon] = useState(SIBYLITY_ICON);
    const [brandName, setBrandName] = useState('Sibylity');
    const [thumbnail, setThumbnail] = useState({ file: SIBYLITY_HELP_RIGHT, sourceType: 'URL' });
    const [spinner, setSpinner] = useState({ file: SIBYLITY_HELP_RIGHT, sourceType: 'URL' });
    const [theme, setTheme] = useState(null);

    useEffect(() => {
        const settings = tenant?.settings ? tenant.settings : {};
        if (settings.branded_name) {
            setBrandName(settings.branded_name);
        }

        if (settings.logo_file) {
            setLogo(settings.logo_file.startsWith('data:image/x-icon;base64,')
                ? settings.logo_file
                : 'data:image/x-icon;base64,' + settings.logo_file);
        } else {
            setLogo(SIBYLITY_LOGO);
        }

        if (settings.fav_file) {
            setFavicon(settings.fav_file.startsWith('data:image/x-icon;base64,')
                ? settings.fav_file
                : 'data:image/x-icon;base64,' + settings.fav_file);
        } else {
            setFavicon(SIBYLITY_ICON);
        }

        if (settings.helpright_file) {
            setThumbnail({
                file: settings.helpright_file,
                sourceType: 'TEXT'
            });
        } else {
            setThumbnail({
                file: SIBYLITY_HELP_RIGHT,
                sourceType: 'URL'
            });
        }

        if (settings.thumbnail) {
            setSpinner({
                file: settings.thumbnail,
                sourceType: 'TEXT'
            });
        } else {
            setSpinner({
                file: SIBYLITY_THUMBNAIL,
                sourceType: 'URL'
            });
        }

        if (tenant) {
            setTheme(themePicker(tenant, themeKey));
        } else {
            setTheme(themePicker(null, themeKey));
        }
    }, [tenant?.settings, tenant]);

    return { brandName, logo, favicon, thumbnail, spinner, theme };
};
