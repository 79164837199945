// CSPHelper.js
// Helper module for generating a robust Content Security Policy for production and development environments
const BASE_POLICY = {
    "default-src": "'self'",
    "style-src": ["'self'", "'unsafe-inline'"],
    "script-src": ["'self'", "'unsafe-inline'", "'unsafe-eval'", "https://cdn.logrocket.io", "https://cdn.lr-ingest.io", "https://cdn.lr-in.com", "https://cdn.lr-in-prod.com", "https://cdn.lr-ingest.com", "https://cdn.ingest-lr.com", "https://cdn.lr-intake.com", "https://cdn.intake-lr.com", "https://cdn.logr-ingest.com", "https://js.stripe.com"],
    "img-src": ["'self'", "https:", "http:", "data:", "blob:"],
    "font-src": ["'self'"],
    "form-action": ["'self'"],
    "connect-src": ["'self'", "https://*.logrocket.io", "https://*.lr-ingest.io", "https://*.logrocket.com", "https://*.lr-in.com", "https://*.lr-in-prod.com", "https://*.lr-ingest.com", "https://*.ingest-lr.com", "https://*.lr-intake.com", "https://*.intake-lr.com", "https://*.logr-ingest.com"],
    "media-src": ["'self'"],
    "frame-src": ["'self'", "https://js.stripe.com"],
    "object-src": ["'self'"],
    "child-src": ["'self'", "blob:", "https://js.stripe.com"],
    "worker-src": ["'self'", "blob:"],
    "frame-ancestors": ["'none'"],
    "upgrade-insecure-requests": ""
};

const DEFAULT_SOURCES = ["https://cdn.sibylity.com"];

// Utility to format CSP directives as string
const formatCSP = (directives) => {
    return Object.entries(directives).reduce((policy, [key, values]) => {
        if (Array.isArray(values)) {
            values = values.join(' ');
        }
        return `${policy}${key} ${values}; `;
    }, '');
};

// Adds additional sources to the CSP directives based on environment settings
const enhanceCSPWithDynamicSources = (directives, additionalSources) => {
    Object.keys(directives).forEach(key => {
        if (Array.isArray(directives[key]) && additionalSources[key]) {
            directives[key] = [...new Set([...directives[key], ...additionalSources[key]])];
        }
    });
};

// Construct CSP with dynamic sources if provided
export const createContentSecurityPolicy = (settings) => {
    const directives = JSON.parse(JSON.stringify(BASE_POLICY)); // Deep clone to prevent mutation of the base policy
    const additionalSources = {};

    if (settings.csp_whitelist) {
        settings.csp_whitelist.forEach(entry => {
            try {
                const url = new URL(entry.domain);
                const protocolAndHost = `${url.protocol}//${url.host}`;
                Object.keys(directives).forEach(key => {
                    if (!additionalSources[key]) {
                        additionalSources[key] = [];
                    }
                    additionalSources[key].push(protocolAndHost);
                });
            } catch (error) {
                console.error(`Error parsing URL from CSP whitelist: ${entry.domain}`, error);
            }
        });
    }

    // Ensure default sources are included
    Object.keys(directives).forEach(key => {
        if (Array.isArray(directives[key])) {
            directives[key] = [...new Set([...directives[key], ...DEFAULT_SOURCES])];
        }
    });

    enhanceCSPWithDynamicSources(directives, additionalSources);
    return formatCSP(directives);
};
