// AutoRunHelper.js
// Helper module for managing auto-run settings in local storage

/**
 * Retrieves the auto-run setting from local storage.
 * Returns true if the setting is enabled, defaults to true if undefined or invalid.
 */
export const getAutoRunSetting = () => {
    try {
        const setting = localStorage.getItem('autoRunHelp');
        // Ensure the return is strictly boolean
        return setting !== null ? setting === 'true' : true;
    } catch (error) {
        console.error("Error retrieving auto-run setting from local storage:", error);
        return true;  // Default to true in case of error
    }
};

/**
 * Sets the auto-run setting in local storage.
 * Accepts any input, but treats it as a boolean to normalize the value.
 * @param {boolean|string|undefined} isEnabled - intended new state of auto-run, interpreted as boolean.
 */
export const setAutoRunSetting = (isEnabled) => {
    const normalizedIsEnabled = Boolean(isEnabled); // Convert to strict boolean - 'false', undefined, null, 0 become false
    try {
        localStorage.setItem('autoRunHelp', normalizedIsEnabled ? 'true' : 'false');
    } catch (error) {
        console.error("Error setting auto-run in local storage:", error);
    }
};

