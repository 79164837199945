let tenant = localStorage.getItem("sibtenantId") ? localStorage.getItem("sibtenantId") : "";
let roles = localStorage.getItem("sibroles") ? localStorage.getItem("sibroles") : "";

const url = new URL(window.location.href);
const hostname = url.hostname;
const domainTenant = hostname.split('.')[0];

const defaultTenants = ['www', 'sibylity', 'sibdev', 'sibtest'];
const isDeployed = process.env.NODE_ENV === 'production';
let isDefault = true;

if (isDeployed && !defaultTenants.includes(domainTenant) && isNaN(Number(domainTenant))) {
    tenant = domainTenant;
    isDefault = false;
}

export const initialState = {
    roles: [] || roles,
    tenantId: "" || tenant,
    tenant: {},
    default: isDefault,
    profile: {},
    loading: false,
    errorMessage: null
};

export const AuthReducer = (initialState, action) => {
    switch (action.type) {
        case "REQUEST_LOGIN":
            return {
                ...initialState,
                loading: true
            };
        case "LOGIN_SUCCESS":
            return {
                ...initialState,
                roles: action.payload.roles,
                tenant: action.payload.tenant,
                tenantId: action.payload.tenantId,
                profile: action.payload.profile,
                loading: false
            };
        case "LOGOUT":
            return {
                ...initialState,
                roles: [],
                tenant: {},
                profile: {},
                tenantId: ""
            };

        case "LOGIN_ERROR":
            return {
                ...initialState,
                loading: false,
                errorMessage: action.error
            };

        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
