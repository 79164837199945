import cloneDeep from 'lodash/cloneDeep';


const themes = {
    sibylity: {
        palette: {
            type: "light",
            primary: {
                main: "#ce5c1d",
                dark: "#7c3711",
                light: "#e29d77",
                contrastText: "rgba(255,255,255,0.91)",
                contrast: "rgba(255,255,255,0.91)"
            },
            secondary: {
                main: "#686C9B",
                dark: "#494c6d",
                light: "#9598b9",
                contrastText: "rgba(255,255,255,0.91)",
                contrast: "rgba(255,255,255,0.91)"
            }
        },
        typography: {
            fontFamily: "Roboto, Helvetica, Arial, sans-serif"
        },
        components: {
            MuiTypography: {
                defaultProps: {
                    fontFamily: "Roboto, Helvetica, Arial, sans-serif"
                }
            },
            muitooltip: {
                styleoverrides: {
                    tooltip: {
                        fontSize: '1em'
                    }
                }
            }
        },
        props: {
            MuiButton: {
                size: "small"
            },
            MuiButtonGroup: {
                size: "small"
            },
            MuiCheckbox: {
                size: "small"
            },
            MuiFab: {
                size: "small"
            },
            MuiFormControl: {
                margin: "dense",
                size: "small"
            },
            MuiFormHelperText: {
                margin: "dense"
            },
            MuiIconButton: {
                size: "small"
            },
            MuiInputBase: {
                margin: "dense"
            },
            MuiInputLabel: {
                margin: "dense"
            },
            MuiRadio: {
                size: "small"
            },
            MuiSwitch: {
                size: "small"
            },
            MuiTextField: {
                size: "small"
            }
        },
        shape: {
            borderRadius: 4
        }
    },
    fcr: {
        palette: {
            type: "light",
            primary: {
                main: "#0092DF",
                dark: "#005886",
                light: "#66beec",
                contrastText: "rgba(255,255,255,0.91)"
            },
            secondary: {
                main: "#686C9B",
                dark: "#494c6d",
                light: "#9598b9",
                contrastText: "rgba(255,255,255,0.91)",
                contrast: "rgba(255,255,255,0.91)"
            }
        },
        typography: {
            fontFamily: "Roboto, Helvetica, Arial, sans-serif"
        },
        components: {
            MuiTypography: {
                defaultProps: {
                    fontFamily: "Roboto, Helvetica, Arial, sans-serif"
                }
            },
            muitooltip: {
                styleoverrides: {
                    tooltip: {
                        fontSize: '1em'
                    }
                }
            }
        },
        props: {
            MuiButton: {
                size: "small"
            },
            MuiButtonGroup: {
                size: "small"
            },
            MuiCheckbox: {
                size: "small"
            },
            MuiFab: {
                size: "small"
            },
            MuiFormControl: {
                margin: "dense",
                size: "small"
            },
            MuiFormHelperText: {
                margin: "dense"
            },
            MuiIconButton: {
                size: "small"
            },
            MuiInputBase: {
                margin: "dense"
            },
            MuiInputLabel: {
                margin: "dense"
            },
            MuiRadio: {
                size: "small"
            },
            MuiSwitch: {
                size: "small"
            },
            MuiTextField: {
                size: "small"
            }
        },
        shape: {
            borderRadius: 4
        }
    }
};

export function template(tenant, themeKey) {
    if (!themeKey || !themes[themeKey]) {
        themeKey = 'sibylity';
    }

    let theme = cloneDeep(themes[themeKey]);
    if (tenant?.settings) {
        let key = themeKey + '_color_primary_main';
        if (tenant.settings[key]?.length > 0) {
            theme.palette.primary.main = tenant.settings[key];
        }
        key = themeKey + '_color_primary_dark';
        if (tenant.settings[key]?.length > 0) {
            theme.palette.primary.dark = tenant.settings[key];
        }
        key = themeKey + '_color_primary_light';
        if (tenant.settings[key]?.length > 0) {
            theme.palette.primary.light = tenant.settings[key];
        }
        key = themeKey + '_color_primary_contrast';
        if (tenant.settings[key]?.length > 0) {
            theme.palette.primary.contrast = tenant.settings[key];
            theme.palette.primary.contrastText = tenant.settings[key];
        }
        key = themeKey + '_color_secondary_main';
        if (tenant.settings[key]?.length > 0) {
            theme.palette.secondary.main = tenant.settings[key];
        }
        key = themeKey + '_color_secondary_dark';
        if (tenant.settings[key]?.length > 0) {
            theme.palette.secondary.dark = tenant.settings[key];
        }
        key = themeKey + '_color_secondary_light';
        if (tenant.settings[key]?.length > 0) {
            theme.palette.secondary.light = tenant.settings[key];
        }
        key = themeKey + '_color_secondary_contrast';
        if (tenant.settings[key]?.length > 0) {
            theme.palette.secondary.contrast = tenant.settings[key];
            theme.palette.secondary.contrastText = tenant.settings[key];
        }
        key = themeKey + '_font_families';
        if (tenant.settings[key]?.length > 0) {
            theme.components.MuiTypography.defaultProps.fontFamily = tenant.settings[key];
            theme.typography.fontFamily = tenant.settings[key];
        }
    }

    return theme;
};