import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography, Paper } from '@mui/material';
import LogRocket from 'logrocket';

const ERROR_IMAGE =
    "https://cdn.sibylity.com/static/common/images/thia_faq.png";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        LogRocket.captureException(error, {
            extra: errorInfo,
        });
        this.setState({ error: error, errorInfo: errorInfo });
    }

    render() {
        if (this.state.hasError) {
            return (
                <Box sx={{ width: '90%', display: 'flex', flexDirection: 'column', height: '100%', margin: 'auto' }}>
                    <Paper sx={{ minHeight: "65vh", px: 2, pt: 1, mt: 4 }} elevation={3}>
                        <Grid container justifyContent="space-between" sx={{ mt: 2, mx: 2 }}>
                            <Grid item xs={12} sm={12} md={6}>
                                <Grid container sx={{ height: '100%' }} justifyContent="center" alignItems="center">
                                    <Grid item>
                                        <Typography variant="h4" align="center" color="primary">
                                            Oops! Something went wrong.
                                        </Typography>
                                        <Typography sx={{ fontSize: 16, fontWeight: 300 }} align="center">
                                            We&apos;re having some trouble completing your request. Please try refreshing the page, or you can find the support button at the top of the page, near the right end but to the left of the user menu, for assistance.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={0} sm={0} md={6} sx={{ px: 3 }}>
                                <Grid container justifyContent="center" alignItems="flex-end">
                                    <img src={ERROR_IMAGE} alt="Error Illustration" height={500} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
            );
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node
};

export default ErrorBoundary;
