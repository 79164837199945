import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useEffect, useState, lazy, Suspense } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import PrivateRouteWithKey from './PrivateRouteWithKey';
import PublicRouteWithKey from './PublicRouteWithKey';
import PageLoader from '../components/PageLoader';
import { themePicker } from '../theme/themePicker';
import { useAuthState } from '../helpers/Context';
import { Box } from '@mui/system';

// Layouts
const DashboardLayout = lazy(() => import('../layouts/DashboardLayout'));
const MockModalLayout = lazy(() => import('../layouts/MockModalLayout'));
const AuthLayout = lazy(() => import('../layouts/AuthLayout'));
// Pages
const Page404Page = lazy(() => import('../pages/Page404Page'));
const BaselinePage = lazy(() => import('../pages/BaselinePage'));
const BaselinesPage = lazy(() => import('../pages/BaselinesPage'));
const RiskProjectsPage = lazy(() => import('../pages/RiskProjectsPage'));
const UserDashboardPage = lazy(() => import('../pages/UserDashboardPage'));
const RiskDashboardPage = lazy(() => import('../pages/RiskDashboardPage'));
const CommonControlPage = lazy(() => import('../pages/CommonControlPage'));
const AdminDashboardPage = lazy(() => import('../pages/AdminDashboardPage'));
const CommonControlsPage = lazy(() => import('../pages/CommonControlsPage'));
const ComplianceReportsPage = lazy(() => import('../pages/ComplianceReportsPage'));
const AuthenticationFormPage = lazy(() => import('../pages/AuthenticationFormPage'));
const ActivityRemediationPage = lazy(() => import('../pages/ActivityRemediationPage'));
const AdministrationUsersPage = lazy(() => import('../pages/AdministrationUsersPage'));
const ActivityPlanApprovalPage = lazy(() => import('../pages/ActivityPlanApprovalPage'));
const RiskProjectQuickPlanPage = lazy(() => import('../pages/RiskProjectQuickPlanPage'));
const AdministrationSettingsPage = lazy(() => import('../pages/AdministrationSettingsPage'));
const ActivityDataClassificationPage = lazy(() => import('../pages/ActivityDataClassificationPage'));
const ActivityImpactAssessmentPage = lazy(() => import('../pages/ActivityImpactAssessmentPage'));
const CommonControlDashboardPage = lazy(() => import('../pages/CommonControlDashboardPage'));
const AdministrationAccessPage = lazy(() => import('../pages/AdministrationAccessPage'));
const RiskProjectAdvancedPage = lazy(() => import('../pages/RiskProjectAdvancedPage'));
const ConfigureSettingsPage = lazy(() => import('../pages/ConfigureSettingsPage'));
const ProfileQuestionsPage = lazy(() => import('../pages/ProfileQuestionsPage'));
const DocumentViewerPage = lazy(() => import('../pages/DocumentViewerPage'));
const DocumentationPage = lazy(() => import('../pages/DocumentationPage'));
const RemediationsPage = lazy(() => import('../pages/RemediationsPage'));
const AdminReportsPage = lazy(() => import('../pages/AdminReportsPage'));
const RiskReportsPage = lazy(() => import('../pages/RiskReportsPage'));
const RemediationPage = lazy(() => import('../pages/RemediationPage'));
const UserProfilePage = lazy(() => import('../pages/UserProfilePage'));
const QuickPlansPage = lazy(() => import('../pages/QuickPlansPage'));
const GameRulesPage = lazy(() => import('../pages/GameRulesPage'));
const QuickPlanPage = lazy(() => import('../pages/QuickPlanPage'));
// Components
const SSOCodeFunction = lazy(() => import('../components/authentication/SSOCodeFunction'));
const ContextFunction = lazy(() => import('../helpers/Context/ContextFunction'));


function Router() {
  const [fcrTheme, setFCRTheme] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const state = useAuthState();
  let location = useLocation();

  useEffect(() => {
    if (state) {
      if (state?.roles?.isRiskRole) {
        setFCRTheme(themePicker(state.tenant, 'fcr'));
      }
    }
  }, [state]);

  const handleStateChange = () => {
    setIsReady(true);
  };

  return (
    <Box>
      <Suspense fallback={<PageLoader />}>
        {!isReady &&
          <ContextFunction changeHandler={handleStateChange} />
        }
        {isReady &&
          <Switch location={location}>
            <PrivateRouteWithKey path="/" exact component={UserDashboardPage} layout={DashboardLayout} />
            <PrivateRouteWithKey path="/activity/classification/:task?" exact component={ActivityDataClassificationPage} layout={MockModalLayout} />
            <PrivateRouteWithKey path="/activity/impacts/:task?" exact component={ActivityImpactAssessmentPage} layout={MockModalLayout} />
            <PrivateRouteWithKey path="/activity/approve/:task?" exact component={ActivityPlanApprovalPage} layout={MockModalLayout} />
            <PrivateRouteWithKey path="/activity/remediate/:task?" exact component={ActivityRemediationPage} layout={MockModalLayout} />
            <PrivateRouteWithKey path="/game/rules" exact component={GameRulesPage} layout={MockModalLayout} />
            <PrivateRouteWithKey path="/document/:type/:resource?" exact component={DocumentViewerPage} layout={MockModalLayout} />
            <PrivateRouteWithKey path="/user/profile" exact component={UserProfilePage} layout={MockModalLayout} />
            {state.roles.isRiskRole && fcrTheme &&
              <Route path="/risk">
                <Switch location={location}>
                  <PrivateRouteWithKey path="/risk/dashboard" exact component={RiskDashboardPage} layout={DashboardLayout} theme={fcrTheme} riskRequired={true} versionKey={['enterprise', 'professional']} />
                  <PrivateRouteWithKey path="/risk/projects" exact component={RiskProjectsPage} layout={DashboardLayout} theme={fcrTheme} riskRequired={true} />
                  <PrivateRouteWithKey path="/risk/project/:resource?" exact component={RiskProjectAdvancedPage} layout={MockModalLayout} theme={fcrTheme} riskRequired={true} />
                  <PrivateRouteWithKey path="/risk/project/quickstart/:resource?" exact component={RiskProjectQuickPlanPage} layout={MockModalLayout} theme={fcrTheme} riskRequired={true} />
                  <PrivateRouteWithKey path="/risk/remediation/plans" exact component={RemediationsPage} layout={DashboardLayout} theme={fcrTheme} riskRequired={true} />
                  <PrivateRouteWithKey path="/risk/remediation/:resource?" exact component={RemediationPage} layout={MockModalLayout} theme={fcrTheme} riskRequired={true} />
                  <PrivateRouteWithKey path="/risk/compliance/checkups" exact component={ComplianceReportsPage} layout={DashboardLayout} theme={fcrTheme} riskRequired={true} />
                  <PrivateRouteWithKey path="/risk/reports" exact component={RiskReportsPage} layout={DashboardLayout} riskRequired={true} versionKey={['enterprise', 'professional']} />
                  <Route path="/risk/*">
                    <MockModalLayout>
                      <Page404Page />
                    </MockModalLayout>
                  </Route>
                </Switch>
              </Route>
            }
            {state.roles.isRiskRole && fcrTheme &&
              <Route path="/sharing">
                <Switch location={location}>
                  <PrivateRouteWithKey path="/sharing/dashboard" exact component={CommonControlDashboardPage} layout={DashboardLayout} theme={fcrTheme} riskRequired={true} versionKey={['enterprise', 'professional']} permissionKey={['group_fcr_users_can_share_common_controls']} />
                  <PrivateRouteWithKey path="/sharing/controls" exact component={CommonControlsPage} layout={DashboardLayout} theme={fcrTheme} riskRequired={true} versionKey={['enterprise', 'professional']} permissionKey={['group_fcr_users_can_share_common_controls']} />
                  <PrivateRouteWithKey path="/sharing/control/:resource?" exact component={CommonControlPage} layout={MockModalLayout} theme={fcrTheme} riskRequired={true} versionKey={['enterprise', 'professional']} permissionKey={['group_fcr_users_can_share_common_controls']} />
                  <Route path="/sharing/*">
                    <MockModalLayout>
                      <Page404Page />
                    </MockModalLayout>
                  </Route>
                </Switch>
              </Route>
            }
            {state.roles.isRiskRole &&
              <Route path="/configure">
                <Switch location={location}>
                  <PrivateRouteWithKey path="/configure/questions" exact component={ProfileQuestionsPage} layout={DashboardLayout} riskRequired={true} versionKey={['enterprise', 'professional']} permissionKey={['group_fcr_users_can_share_profiles', 'group_fcr_users_can_manage_questions']} />
                  <PrivateRouteWithKey path="/configure/profiles" exact component={QuickPlansPage} layout={DashboardLayout} riskRequired={true} permissionKey={['group_fcr_users_can_share_profiles']} />
                  <PrivateRouteWithKey path="/configure/profile/:resource?" exact component={QuickPlanPage} layout={MockModalLayout} riskRequired={true} permissionKey={['group_fcr_users_can_share_profiles']} />
                  <PrivateRouteWithKey path="/configure/baselines" exact component={BaselinesPage} layout={DashboardLayout} riskRequired={true} permissionKey={['group_fcr_users_can_share_baselines']} />
                  <PrivateRouteWithKey path="/configure/baseline/:resource?" exact component={BaselinePage} layout={MockModalLayout} riskRequired={true} permissionKey={['group_fcr_users_can_share_baselines']} />
                  <PrivateRouteWithKey path="/configure/settings" exact component={ConfigureSettingsPage} layout={DashboardLayout} riskRequired={true} permissionKey={['group_fcr_users_can_edit_settings']} />
                  <Route path="/configure/*">
                    <MockModalLayout>
                      <Page404Page />
                    </MockModalLayout>
                  </Route>
                </Switch>
              </Route>
            }
            {state.roles.isAdmin &&
              <Route path="/administrator">
                <Switch location={location}>
                  <PrivateRouteWithKey path="/administrator/dashboard" exact component={AdminDashboardPage} layout={DashboardLayout} adminRequired={true} versionKey={['enterprise', 'professional']} />
                  <PrivateRouteWithKey path="/administrator/users" exact component={AdministrationUsersPage} layout={DashboardLayout} adminRequired={true} permissionKey={['group_admin_can_provision_users']} />
                  <PrivateRouteWithKey path="/administrator/iam" exact component={AdministrationAccessPage} layout={DashboardLayout} adminRequired={true} versionKey={['enterprise', 'professional']} />
                  <PrivateRouteWithKey path="/administrator/reports" exact component={AdminReportsPage} layout={DashboardLayout} adminRequired={true} versionKey={['enterprise', 'professional']} />
                  <PrivateRouteWithKey path="/administrator/settings" exact component={AdministrationSettingsPage} layout={DashboardLayout} adminRequired={true} permissionKey={['group_admin_can_edit_settings']} />
                  <Route path="/administrator/*">
                    <MockModalLayout>
                      <Page404Page />
                    </MockModalLayout>
                  </Route>
                </Switch>
              </Route>
            }
            <PrivateRouteWithKey path="/docs" exact component={DocumentationPage} layout={MockModalLayout} />
            <PublicRouteWithKey path="/auth/login" exact component={AuthenticationFormPage} layout={AuthLayout} />
            <PublicRouteWithKey path="/auth/sso" exact component={SSOCodeFunction} layout={AuthLayout} useErrorBoundary={true} />
            <Route path="*">
              <MockModalLayout>
                <Page404Page />
              </MockModalLayout>
            </Route>
          </Switch>
        }
      </Suspense>
    </Box>
  );
}

export default Router;