
export async function loginUser(dispatch) {
    dispatch({ type: 'REQUEST_LOGIN' });
}

export async function loadContext(dispatch, context) {
    localStorage.setItem('sibroles', JSON.stringify(context.roles));
    localStorage.setItem('sibtenantId', context.tenantId);
    dispatch({ type: 'LOGIN_SUCCESS', payload: context });
}

export async function logout(dispatch) {
    localStorage.removeItem('sibroles');
    localStorage.removeItem('sibtenantId');
    dispatch({ type: 'LOGOUT' });
}