import PropTypes from 'prop-types';
import { Suspense } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Route, Redirect, useLocation } from 'react-router-dom';
import ErrorBoundary from '../components/ErrorBoundary';
import PageLoader from "../components/PageLoader";
import { useAuthState } from '../helpers/Context';
import hasAccess from "../helpers/hasAccess";


const PrivateRouteWithKey = ({ component: Component, layout: Layout, useErrorBoundary = true, theme, ...rest }) => {
    const location = useLocation();
    const key = `${location.pathname}${location.search}`;

    const state = useAuthState();
    const isAuthorized = hasAccess(rest, state);

    return (
        <Route
            {...rest}
            render={(props) => {
                if (!state?.roles?.authenticated) {
                    // Redirect to the login page if not authenticated
                    return <Redirect to={{ pathname: "/auth/login", state: { from: props.location } }} />;
                } else if (!isAuthorized) {
                    // Redirect to an unauthorized access page or a default page based on role
                    return <Redirect to={{ pathname: "/404", state: { from: props.location } }} />;
                } else {
                    const BoundedComponent = useErrorBoundary ? (
                        <ErrorBoundary>
                            <Component {...props} key={key} />
                        </ErrorBoundary>
                    ) : <Component {...props} key={key} />;

                    const ThemedComponent = theme ? (
                        <ThemeProvider theme={theme}>
                            {BoundedComponent}
                        </ThemeProvider>
                    ) : BoundedComponent;

                    const Content = Layout ? (
                        <Layout>
                            {ThemedComponent}
                        </Layout>
                    ) : ThemedComponent;

                    return (
                        <Suspense fallback={<PageLoader />}>
                            {Content}
                        </Suspense>
                    );
                }
            }}
        />
    );
};

PrivateRouteWithKey.propTypes = {
    component: PropTypes.elementType.isRequired,
    layout: PropTypes.elementType,
    theme: PropTypes.object,
    useErrorBoundary: PropTypes.bool
};

export default PrivateRouteWithKey;