import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { AuthProvider } from "./helpers/Context";
import { HelmetProvider } from "react-helmet-async";
import LogRocket from 'logrocket';
import App from './App';


if (process.env.NODE_ENV === 'production') {
  LogRocket.init('vfijdi/sibylity', {
    network: {
      requestSanitizer: (request) => {
        // Redact all headers
        Object.keys(request.headers).forEach(key => {
          request.headers[key] = '[REDACTED]';
        });

        // Redact the entire body
        if (request.body) {
          request.body = '[REDACTED]';
        }

        return request;
      },
      responseSanitizer: (response) => {
        // Redact all headers
        Object.keys(response.headers).forEach(key => {
          response.headers[key] = '[REDACTED]';
        });

        // Redact the entire body
        if (response.body) {
          response.body = '[REDACTED]';
        }

        return response;
      },
    },
  });

  const supportedTypes = PerformanceObserver.supportedEntryTypes;
  const typesToObserve = ['paint', 'mark', 'measure', 'frame', 'navigation', 'resource', 'layout-shift', 'longtask'];

  typesToObserve.forEach(type => {
    if (supportedTypes.includes(type)) {
      const observer = new PerformanceObserver((list) => {
        list.getEntries().forEach(entry => {
          LogRocket.track(`${type}-performance`, entry);
        });
      });
      observer.observe({ type: type, buffered: true });
    }
  });
}

const rootElement = document.getElementById('root');
if (rootElement) {
  try {
    ReactDOM.render(
      <React.StrictMode>
        <HelmetProvider>
          <AuthProvider>
            <App />
          </AuthProvider>
        </HelmetProvider>
      </React.StrictMode>,
      rootElement
    );
  } catch (error) {
    console.error("Failed to mount React application:", error);
  }
} else {
  console.error("Failed to find the root element for the React application.");
}

try {
  serviceWorker.unregister();
} catch (error) {
  console.error("Service worker registration failed:", error);
}
