
const hasAccess = (item, state) => {
    // Check for subscription type
    if (item.versionKey) {
        const allowedTypes = Array.isArray(item.versionKey) ? item.versionKey : [item.versionKey];
        if (!allowedTypes.includes(state.tenant.subscription.type)) {
            return false; // If the subscription type is not allowed, deny access
        }
    }

    // Check for administrative access
    if (item.adminRequired) {
        if (state.roles.isTenantAdmin) return true;
        if (state.roles.isAdmin) {
            return checkPermissionKey(item, state);
        }
    } else if (item.riskRequired) {
        // Check for risk role access
        if (state.roles.isTenantRiskRole) return true;
        if (state.roles.isRiskRole) {
            return checkPermissionKey(item, state);
        }
    } else {
        // If no specific role or subscription is required, allow access
        return true;
    }
    return false;
}

function checkPermissionKey(item, state) {
    if (item.permissionKey) {
        const comparator = Array.isArray(item.permissionKey) ? item.permissionKey : [item.permissionKey];
        return comparator.some(key => state.tenant.settings[key]);
    }
    return true; // If no permission key is defined, allow access
}


export default hasAccess;