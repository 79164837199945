import { useState, useEffect } from 'react';
import { createContentSecurityPolicy } from '../helpers/CSPBuilder';


export const useCSP = (tenant) => {
    const [csp, setCSP] = useState('');

    useEffect(() => {
        const settings = tenant?.settings ? tenant.settings : {};
        const newCSP = createContentSecurityPolicy(settings);
        setCSP(newCSP);
    }, [tenant?.settings]);

    return csp;
};
