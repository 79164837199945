import PropTypes from 'prop-types';
import { Suspense } from 'react';
import { Route, useLocation } from 'react-router-dom';
import ErrorBoundary from '../components/ErrorBoundary';
import PageLoader from "../components/PageLoader";


const PublicRouteWithKey = ({ component: Component, layout: Layout, useErrorBoundary = false, ...rest }) => {
    const location = useLocation();
    const key = `${location.pathname}${location.search}`;

    const BoundedComponent = useErrorBoundary ? (
        <ErrorBoundary>
            <Component {...rest} key={key} />
        </ErrorBoundary>
    ) : <Component {...rest} key={key} />;

    const Content = Layout ? (
        <Layout>
            {BoundedComponent}
        </Layout>
    ) : BoundedComponent;

    return (
        <Suspense fallback={<PageLoader />}>
            <Route
                {...rest}
                render={(props) => Content}
            />
        </Suspense>
    );
};

PublicRouteWithKey.propTypes = {
    component: PropTypes.elementType.isRequired,
    layout: PropTypes.elementType,
    useErrorBoundary: PropTypes.bool
};

export default PublicRouteWithKey;